import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import * as config from '../config';
import { Button, Container, Dimmer, Divider, Form, Header, Loader } from 'semantic-ui-react';
import md5 from 'md5';

const defaultData = {
    email: '',
    password: '',
    rePassword: '',
};

function ActivatePage(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState(defaultData);
    const [data, setData] = useState(defaultData);
    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const controller = new AbortController();

    useEffect(() => {
        fetchUser(id);
        return () => {
            if (submitting || loading) {
                controller.abort();
            }
        };
    }, [props]);

    const fetchUser = (id) => {
        setLoading(true);
        axios
            .get(`${config.apiURL}/activate/${id}`, { signal: controller.signal })
            .then((res) => {
                const user = res.data.user;
                // console.log(user);
                setUser(user);
                setData(user);
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
                navigate('/');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChange = (e, { name, value }) => {
        // console.log([name], value);
        if (name == 'firstname' || name == 'lastname') {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }
        setData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    //prevent leading or tailling space in inputs
    const trim = () => {
        for (const key in data) {
            if (typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        return data;
    };

    //return errors
    const validate = () => {
        setData(trim());
        const errors = {};
        const pwdLength = 6;
        if (data.password?.length < pwdLength) {
            errors.password = `At lease ${pwdLength}`;
        }
        if (data.password !== data.rePassword) {
            errors.rePassword = 'Not match';
        }
        if (!data.password) {
            errors.password = 'Can not be blank';
        }

        setErrors(errors);
        return errors;
    };

    const handleSubmit = () => {
        const errors = validate();
        if (Object.keys(errors).length !== 0) {
            console.log('have error');
            return;
        }
        const req = {
            passwordHash: md5(data.password),
        };
        setSubmitting(true);
        axios
            .put(`${config.apiURL}/activate/${user.id}`, req, { signal: controller.signal })
            .then((res) => {
                window.alert(res.data.reply);
                const url = `/?v=${user.email}`;
                navigate(url);
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Container>
            <Dimmer active={loading || submitting} inverted>
                <Loader inverted>加载中...</Loader>
            </Dimmer>
            {user.approved ? (
                <>
                    <Header as="h1">{`恭喜审核通过，请设置登录密码以激活账号`}</Header>
                    <Header as="h3">{`旅行社: ${user.agency.companyName}`}</Header>

                    <Header as="h3">{`用户名: ${user.email}`}</Header>
                    <Divider hidden />
                    <Form widths="equal">
                        <Form.Group>
                            <Form.Input
                                name="password"
                                fluid
                                label="设置密码"
                                placeholder="Password"
                                required
                                value={data.password ?? ''}
                                onChange={onChange}
                                error={errors.password}
                                type="password"
                                autoComplete="new-password"
                            />
                            <Form.Input
                                name="rePassword"
                                fluid
                                label="确认密码"
                                placeholder="Confirm Password"
                                value={data.rePassword ?? ''}
                                onChange={onChange}
                                error={errors.rePassword}
                                required
                                type="password"
                                autoComplete="new-password"
                            />
                        </Form.Group>
                    </Form>

                    <Button positive floated="right" type="submit" onClick={handleSubmit}>
                        激活
                    </Button>
                </>
            ) : (
                <>
                    <Header as="h2">感谢您的注册</Header>
                    <Header>请耐心等待，审核通过后会发送确认邮件。</Header>
                    <br />
                    <Button onClick={() => navigate('/')}>返回</Button>
                </>
            )}
        </Container>
    );
}

export default ActivatePage;
